import React, { Component } from 'react';
import Input from '../../components/UI/Imput/Imput';
import {updateObject, checkValidity, maskInput } from '../../shared/utility';
import Spinner from '../../components/UI/Spinner/Spinner';
import Toolbar from '../../components/Navigarion/Toolbar/Toolbar';
import Button from '../../components/UI/Button/Button';
import registryForm from './RegisterFormConfig';

import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';


const initialState = {
    registryForm: registryForm,
    formIsValid: false,
    isLoading: false,
    registerID: null

};


class Register extends Component {
 
    constructor(props) {
        super(props)
        this.state = initialState;        
    }

    reset() {        
        this.setState(initialState);
    }

    registraColeta = async event => { 
        event.preventDefault();

        this.setState({
            isLoading: true
        })

        let company;

        await Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            company = user.attributes["custom:company"];
        })
        .catch(err => {
            return err;
        });

        let bodyMap = {};
        for (let key in this.state.registryForm) {
            
            if(key !== "patologias") {
                if (this.state.registryForm[key].value) {
                    bodyMap[key] = this.state.registryForm[key].value;
                }                
            } else {
                let patologias = [];
                for (let patologia in this.state.registryForm.patologias) {    
                                    
                    if(this.state.registryForm.patologias[patologia].value) {
                        patologias.push(this.state.registryForm.patologias[patologia].label);
                    }                    
                }                
                bodyMap[key] =  patologias;
            }
        }

        bodyMap['company']=company;      
       
        let postData = {
            body: bodyMap
        }        
        API.post('aquisitor-api', '/registry', postData ).then(response => {            
            
            console.log(response)
            if(response.statusCode ===  "200") {
                const body = JSON.parse(response.body)
                this.setState({
                    registerID: body.Timestamp
                })
            }

            this.setState({isLoading: false});

        }).catch(error => {     
            console.log(error);       
            console.log(error.response)
            this.setState({isLoading: false});
        });
    }
  
        

    inputChangedHandler = (event, inputIdentifier) => {
                
        if(typeof this.state.registryForm[inputIdentifier] !== "undefined") {

            let formIsValid = true;
            
            const input = maskInput(event.target.value, this.state.registryForm[inputIdentifier].mask);

            const updatedFormElement = updateObject(this.state.registryForm[inputIdentifier], {
                value: input,
                valid: checkValidity(input, this.state.registryForm[inputIdentifier].validation),
                touched: true
            });
            const updatedRegistryForm = updateObject(this.state.registryForm, {
                [inputIdentifier]: updatedFormElement
            });            
                   
            this.setState({registryForm: updatedRegistryForm, formIsValid: formIsValid});

        } else if (typeof this.state.registryForm["patologias"] !== "undefined") {

            const updatedPatologiaElement = updateObject(this.state.registryForm["patologias"][inputIdentifier], {
                value: event.target.value,
                valid: checkValidity(event.target.value, this.state.registryForm["patologias"][inputIdentifier].validation),
                touched: true
            });
            const updatedPatologias = updateObject(this.state.registryForm.patologias, {
                [inputIdentifier]: updatedPatologiaElement
            });

            const updatedRegistryForm = updateObject(this.state.registryForm, {
                patologias: updatedPatologias
            });            
            
            this.setState({registryForm: updatedRegistryForm});

        }
        
    }

    goToAcquisition = acquisitionID =>{                
        this.props.history.push('/acquisition?id=' +acquisitionID+"&cpf=" + this.state.registryForm.cpf.value);       
    }   
    
    render() { 
        
        const patologiasArray = [];
        for ( let key in this.state.registryForm.patologias ) {
            
            patologiasArray.push( {
                id: key,
                config: this.state.registryForm.patologias[key]
            } );
        }

        let form = (
            <form className={'registyForm'} onSubmit={this.registraColeta}>
                <Input                         
                    label={this.state.registryForm.cpf.label}
                    elementType={this.state.registryForm.cpf.elementType}
                    elementConfig={this.state.registryForm.cpf.elementConfig}
                    value={this.state.registryForm.cpf.value}
                    invalid={!this.state.registryForm.cpf.valid}
                    shouldValidate={this.state.registryForm.cpf.validation}
                    touched={this.state.registryForm.cpf.touched}
                    changed={(event) => this.inputChangedHandler(event, 'cpf')} />
                
                <Input                         
                    label={this.state.registryForm.nome.label}
                    elementType={this.state.registryForm.nome.elementType}
                    elementConfig={this.state.registryForm.nome.elementConfig}
                    value={this.state.registryForm.nome.value}
                    invalid={!this.state.registryForm.nome.valid}
                    shouldValidate={this.state.registryForm.nome.validation}
                    touched={this.state.registryForm.nome.touched}
                    changed={(event) => this.inputChangedHandler(event, 'nome')} />

                <Input                         
                    label={this.state.registryForm.idade.label}
                    elementType={this.state.registryForm.idade.elementType}
                    elementConfig={this.state.registryForm.idade.elementConfig}
                    value={this.state.registryForm.idade.value}
                    invalid={!this.state.registryForm.idade.valid}
                    shouldValidate={this.state.registryForm.idade.validation}
                    touched={this.state.registryForm.idade.touched}
                    changed={(event) => this.inputChangedHandler(event, 'idade')} />
                <Input                         
                    label={this.state.registryForm.etnia.label}
                    elementType={this.state.registryForm.etnia.elementType}
                    elementConfig={this.state.registryForm.etnia.elementConfig}
                    value={this.state.registryForm.etnia.value}
                    invalid={!this.state.registryForm.etnia.valid}
                    shouldValidate={this.state.registryForm.etnia.validation}
                    touched={this.state.registryForm.etnia.touched}
                    changed={(event) => this.inputChangedHandler(event, 'etnia')} />

                <Input                         
                    label={this.state.registryForm.jejum.label}
                    elementType={this.state.registryForm.jejum.elementType}
                    elementConfig={this.state.registryForm.jejum.elementConfig}
                    value={this.state.registryForm.jejum.value}
                    invalid={!this.state.registryForm.jejum.valid}
                    shouldValidate={this.state.registryForm.jejum.validation}
                    touched={this.state.registryForm.jejum.touched}
                    changed={(event) => this.inputChangedHandler(event, 'jejum')} />

                {/* <Input                             
                    label={this.state.registryForm.medicamentos.label}
                    elementType={this.state.registryForm.medicamentos.elementType}
                    elementConfig={this.state.registryForm.medicamentos.elementConfig}
                    value={this.state.registryForm.medicamentos.value}
                    invalid={!this.state.registryForm.medicamentos.valid}
                    shouldValidate={this.state.registryForm.medicamentos.validation}
                    touched={this.state.registryForm.medicamentos.touched}
                    changed={(event) => this.inputChangedHandler(event, 'medicamentos')} />         */}

                <Input                         
                    label={this.state.registryForm.sexo.label}
                    elementType={this.state.registryForm.sexo.elementType}
                    elementConfig={this.state.registryForm.sexo.elementConfig}
                    value={this.state.registryForm.sexo.value}
                    invalid={!this.state.registryForm.sexo.valid}
                    shouldValidate={this.state.registryForm.sexo.validation}
                    touched={this.state.registryForm.sexo.touched}
                    changed={(event) => this.inputChangedHandler(event, 'sexo')} />

                {/* <div className="parologias">
                    <label>Patologias</label>
                    {patologiasArray.map(patologia => {                       
                       
                        return(<Input  
                            key={patologia.id}                      
                            label={patologia.config.label}
                            elementType={patologia.config.elementType}
                            elementConfig={patologia.config.elementConfig}
                            value={patologia.config.value}
                            invalid={!patologia.config.valid}
                            shouldValidate={patologia.config.validation}
                            touched={patologia.config.touched}
                            changed={(event) => this.inputChangedHandler(event, patologia.id)} />)
                    })}

                </div> */}

                <Input                         
                    label={this.state.registryForm.obs.label}
                    classes={"wide"}
                    elementType={this.state.registryForm.obs.elementType}
                    elementConfig={this.state.registryForm.obs.elementConfig}
                    value={this.state.registryForm.obs.value}
                    invalid={!this.state.registryForm.obs.valid}
                    shouldValidate={this.state.registryForm.obs.validation}
                    touched={this.state.registryForm.obs.touched}
                    changed={(event) => this.inputChangedHandler(event, 'obs')} />
                

               <div className="wide-field">
                <Button typeype="success" disabled={!this.state.formIsValid}>Registrar</Button>
               </div>
               
            </form>
        );

        

        if (this.state.registerID) {
            form = (
                <div className="register-success">
                    <p>Resgistro Realizado com sucesso!</p>
                    <p>Numero de identificação: <span className={"register-id"}>{this.state.registerID}</span></p>
                    <Button className={"classic"} clicked={this.reset.bind(this)} typeype="success" onClick >Novo Registro</Button>
                    <Button className={"classic"} clicked={()=>this.goToAcquisition(this.state.registerID)} typeype="success" >Seguir para aquisição</Button>                                    
                </div>
                
            )
        }

        
        return (
            <>
            <Toolbar/>
            <Spinner isLoading={this.state.isLoading} />
            <div className="registro-form">
                <h2>Registro</h2>
                {form}               
                
            </div>
            </>
        )
    }
}

export default Register;