import React, {useState, useEffect, useRef} from "react";
import Button from '../../components/UI/Button/Button';
//import communicator from "./Communicator";
import Spinner from "../UI/Spinner/Spinner";
import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import { connect } from 'react-redux';
import { Train, Loads  } from './Constants'
import etapa1 from '../../assets/images/etapa1.png';
import etapa2 from '../../assets/images/etapa2.png';
import etapa3 from '../../assets/images/etapa3.png';
import etapa4 from '../../assets/images/etapa4.png';
import etapa5 from '../../assets/images/etapa5.png';

const Aquisitor = props => {

    const item = props.item;
    const [step, setStep] = useState(0);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const stepList = ["limpeza", "calibration", "acquisition", "sending", "done"];
    
    const [calibration, setCalibration] = useState();
    const [acquisition, setAcquisition] = useState();
    const [startColect, setStartColect] = useState(false);
    

    const [countDown, setCountDown] = useState(0);

    let serialNumber = null;
    

    const postAcquisition = async ()=> {

        let company;

        await Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            company = user.attributes["custom:company"];
        })
        .catch(err => {
            return err;
        });

        getSerialNumber().then(()=>{
            setIsLoadingData(true);
            const postItem = { "body" : {
                    "id" : item.timestamp,
                    "cpf": item.cpf,
                    "serialNumber" : serialNumber,
                    "calibration": calibration, 
                    "acquisition" : acquisition,
                    "company" : company
                }                    
            }

            API.post('aquisitor-api', '/acquisition', postItem ).then(response => {            
                    
                if(response.statusCode ===  200) {
                    //const body = JSON.parse(response.body)
                    setStep(4);
                    props.successHandler()
                }        
                setIsLoadingData(false);

            }).catch(error => {                     
                props.errorHandler([error.response]);                   
                setIsLoadingData(false);
            });
        })        
    }
    
   
    const isolateSamples = (samples)=>{

        let arrayCheck = [];
        let ledsCheck = [[],[],[],[],[]];

        for (let i = 0; i < samples.length; i++) {

            const opt = samples[i][2];
            const ledStatus = samples[i][1];          
                
            arrayCheck.push(1);

            switch (ledStatus.toString()) {
                case "1,0,0,0,0":
                    ledsCheck[4].push(opt)
                    break;
                case "0,1,0,0,0":
                    ledsCheck[3].push(opt)
                    break;
                case "0,0,1,0,0":
                    ledsCheck[2].push(opt)
                    break;
                case "0,0,0,1,0":
                    ledsCheck[1].push(opt)
                    break;
                case "0,0,0,0,1":
                    ledsCheck[0].push(opt)
                    break;                
                default:
                    break;
            }
        }

        let led1Med = Math.floor(ledsCheck[0].reduce((a, b) => a + b) / ledsCheck[0].length);
        let led2Med = Math.floor(ledsCheck[1].reduce((a, b) => a + b) / ledsCheck[1].length);
        let led3Med = Math.floor(ledsCheck[2].reduce((a, b) => a + b) / ledsCheck[2].length);
        let led4Med = Math.floor(ledsCheck[3].reduce((a, b) => a + b) / ledsCheck[3].length);
        let led5Med = Math.floor(ledsCheck[4].reduce((a, b) => a + b) / ledsCheck[4].length);
        
        const isoArray = [led1Med, led2Med, led3Med, led4Med, led5Med];

        return isoArray
    }

    const performSpecCheck = (_check, isCalibrador) => { 
        

       

        //const isoArray = isolateSamples(_check.samples);       

        //console.log(isoArray);
        

        console.log(_check.samples.length)
        console.log(Loads)
        const temp = []
        const spec = []
        for (let i = 0; i < _check.samples.length; i++) {
            const sample = _check.samples[i];            
            if (sample[3]) {
                temp.push(Number(sample[3])) 
            }
            spec.push(sample[2])
        }
        const tempMean = temp.reduce((a, b) => a + b, 0) / temp.length;
        const spectTwo = spec.map(point=>point/tempMean) 
        const specMax = Math.max(...spectTwo)
        const spectThree = spectTwo.map(point=>point/specMax)
        const spectFour = spectThree.map((point, index)=>point-Train[index])
        const spectFive = spectFour.map((point, index)=>point*Loads[index])
        debugger
        const score = spectFive.reduce((a, b) => a + b )
        if (isCalibrador  && score > 1 && score < 2) {
            console.log(score)
            return true
        } else if (!isCalibrador && score > -1 && score < 0.5) {
            console.log(score)
            return true
        }
        console.log(score)
        return false
    }

    const performLedsCheck = (_check) => {
         // temporariamente desligado por mudanças no aparelho
         return true 
        
        // if (process.env.REACT_APP_ENV == null) {
        //     return true;
        // }
        
        // const isoArray = isolateSamples(_check.samples);       

        // console.log(isoArray);
        // const result = regression.current.transform(isoArray);
        // console.log("result: " +  result);  

        // return result === 5 ? true : false;
    
    }

    const getSerialNumber = async () => {

        return new Promise((resolve)=>{
            props.comunicador.getSerialNumber().then((sn)=>{
                console.log(sn)
                serialNumber = sn;
                resolve()
            })
        })        
    }

    const checkWarming = (counts)=> {
        let baseLine = []
        let topLine = []
        counts.forEach(element => {
            switch (element[1].reduce((x,y)=>x+y)) {
                case 0:
                    baseLine.push(element[2])
                    break;
                case 5:
                    topLine.push(element[2])
                    break;
                default:
                    break;
            }
        });
        const baseTotal = baseLine.reduce((sum, val) => (sum += val));
        const baseAverage = baseTotal / baseLine.length;

        const topTotal = topLine.reduce((sum, val) => (sum += val));
        const topAverage = topTotal / topLine.length;

        console.log("baseAverage: " + baseAverage);
        console.log("topAverage: " + topAverage);

        if (baseAverage < 1800 && topAverage < 3160) {
            return true
        } else {
            return false
        }


    }

    const startCalibration = async () => {          
        setIsLoadingData(true);

        try {

            let keepWarming = true;

            let response = {};
            do {
                response = await props.comunicador.getAcquisiton(7,20,1);               
                
                if (checkWarming(response.response.samples)) {
                    keepWarming = false;
                }
                
                                
            } while (keepWarming);

            

            if (performLedsCheck(response.response) && performSpecCheck(response.response, true)) {                    
                setCalibration({...response.response});  
                setIsLoadingData(false);
                setStep(step + 1);                        
            } else {
                setIsLoadingData(false);
                props.errorHandler(["A calibração não foi bem sucedida.", 
                "Verifique a posição da tampa protetora e faça uma nova calibração. Caso seja recorrente, contate suporte técnico."]);
                return;
            }
        } catch (error) {            
            setIsLoadingData(false);
            props.errorHandler(["A calibração não foi bem sucedida.", 
                error.response]);
            console.log(error);
        }
        
    }

    const startAcquisition = async () => {        
        setIsLoadingData(true);        
        setStartColect(true)
        let acquisitionArray = [];

        try {
            let n = 0
            while (n < 5) {
                const response = await props.comunicador.getAcquisiton(7,20,1);                
                
                if (!performSpecCheck(response.response, false) ) {
                    setIsLoadingData(false);
                    props.errorHandler(["Não foi possivel reconhecer a mão no equipamento.", "Posicione a mão corretamente e pressione avançar."]);
                    setAcquisition({});                    
                    return;

                } else {                    
                    acquisitionArray.push(response.response);
                    //setNewAquisition(acquisition ,{...response.response});
                    n++;
                }
            }

            joinAcquisitions(acquisitionArray);

            setIsLoadingData(false);
            setStep(step + 1);                        
            
        } catch (error) {
            setIsLoadingData(false);
            props.errorHandler(["Erro.", 
                error]);
            console.log(error);
        }       
    }

    const joinAcquisitions = (acquisitionArray)=> {
        let acquisitions = acquisitionArray[0];        
        for (let index = 1; index < acquisitionArray.length; index++) {
            const element = acquisitionArray[index];
            
            const samples = [...acquisitions.samples,...element.samples]
            let newAcquisition = {...element};
            newAcquisition.samples = samples;
            newAcquisition.counts = acquisitions.counts + element.counts;                
            acquisitions = {...newAcquisition};
            
        }
        setAcquisition(acquisitions);
    }


   useEffect(
    () => {          
        
        if  (step === stepList.indexOf("sending"))  {
            postAcquisition();
        }  

        if (step === stepList.indexOf("acquisition") && !startColect) {
            
            setTimeout(() => {
                           
                if (countDown >= 120) {
                    setStep(1);
                    setCountDown(0);                    
                } else {                   
                    const val = countDown+1;
                    setCountDown(val);
                }
                
                              
              }, 1000);   
            
        }

        }, [step, countDown]
    );

  

    const returnStepClear = () => (
        <>
        <Spinner isLoading={isLoadingData} >Realizando a calibração.</Spinner>
        <div className={"executar-limpeza"}>
            <img src={etapa1} alt="Etapa Limpeza" />
            <h4>Higienize as mãos - Etapa 1 de 5</h4>
            <p>Higienize as mão  e pressione Avançar</p>
            <p>Voce está realizando a aquisição do registro: <span>{item.timestamp}</span> com Nº prontuário <span>{item.cpf}</span></p>
            
            
            <Button clicked={()=>setStep(1)}>Avançar</Button>
            
        </div>
        </>
    );
 
    const returnStepCalibration = () => (
        <>
        <Spinner isLoading={isLoadingData} ><>Realizando a calibração.<p className="small">(Pode demorar alguns minutos devido ao tempo de <br></br>inatividade do equipamento)</p></></Spinner>
        <div className={"executar-aquisicao"}>
            <img src={etapa2} alt="Etapa calibração" />
            <h4>Calibração - Etapa 2 de 5</h4>
            <p>Confira os dados, encaixe a tampa no equipamento e pressione Avançar</p>
            <p>Voce está realizando a aquisição do registro: <span>{item.timestamp}</span> com Nº prontuário <span>{item.cpf}</span></p>
            
            <Button clicked={()=>startCalibration()}>Avançar</Button>
            
        </div>
        </>
    );
        

    const returnStepAcquisition = () => (
        <>
        <Spinner isLoading={isLoadingData} >Realizando a coleta.</Spinner>
        <div className={"executar-aquisicao"}>
            <img src={startColect? etapa4: etapa3} alt="Etapa aquisicao" />
            <h4>Aquisição - Etapa {startColect? "4 de 5": "3 de 5"}</h4>
            <p>Posicione a palma da mão esquerda sobre o leitor do equipamento de maneira confortavel, em seguida, pressione Avançar</p>
            <p>Voce está realizando a aquisição do registro: <span>{item.timestamp}</span> com Nº  <span>{item.cpf}</span></p>
            <p>Tempo limite para iniciar a coleta: {Math.floor((120 - countDown) / 60)} min. {(120 - countDown) % 60 } seg.</p>
            
            <Button clicked={()=>{startAcquisition()}}>Avançar</Button>
        </div>
        </>

    );

    const returnStepSending = ()=>(
        <>
        <Spinner isLoading={isLoadingData} >Enviando dados.</Spinner>
        <div className={"executar-aquisicao"}>
            
            <h4>Envio - Etapa 5 de 5</h4>
            <p>Enviando os dados para o servidor.</p>
            <p>Voce está realizando a aquisição do registro: <span>{item.timestamp}</span> com Nº prontuário <span>{item.cpf}</span></p>
            
        </div>
        </>
    )

    const returnStepDone = ()=> {
         
        
        return (
        <>
        <div className={"executar-aquisicao"}>
            <img src={etapa5} alt="Etapa aquisicao" />
            <h4>Processo finalizado</h4>            
            <p>O seguinte registo foi realizado com sucesso: <span>{item.timestamp}</span> com Nº prontuário <span>{item.cpf}</span></p>
            <Button clicked={()=>{props.pushHistory('/report?prontuario='+ item.cpf + '&id=' + item.timestamp)}}>Visualizar Resultado</Button>
            <Button clicked={()=>{props.pushHistory('/register')}}>Novo Registro</Button>
            <Button clicked={()=>{props.reloadComponent() }}>Nova Aquisição</Button>
        </div>
        </>
    )}

    let content;
    switch (step) {
        case stepList.indexOf("limpeza"):
            content = returnStepClear();
            break;

        case stepList.indexOf("calibration"):
            content = returnStepCalibration();
            break;
        case stepList.indexOf("check"):
        case stepList.indexOf("acquisition"):
            content = returnStepAcquisition();
            break;
        case stepList.indexOf("sending"):
            content = returnStepSending();
            break;
        case stepList.indexOf("done"):
            content = returnStepDone();
            break;            
        default:
            content = <></>;
            break;
    }
    
    return (<div className={"step-frame"} >{content}</div>)
}

const mapStateToProps = state => {      
	return {
		comunicador: state._comunicador
	}
}
export default connect(mapStateToProps)(Aquisitor);
