import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Toolbar from "../../components/Navigarion/Toolbar/Toolbar";
import Spinner from "../../components/UI/Spinner/Spinner";
import Input from "../../components/UI/Imput/Imput";
import Button from "../../components/UI/Button/Button";
import exportToExcel from "./export";

import { notification } from "antd";
import dayjs from 'dayjs';

import { axiosInstance } from "../../axiosInstance";
import Auth from "@aws-amplify/auth";

function Logs() {
	const [user, setUser] = useState("");
	const [event, setEvent] = useState("");
	const [date, setDate] = useState(getInitialDates());
	const [userName, setUserName] = useState("");
	const [company, setCompany] = useState("");

	const [totalizers, setTotalizers] = useState([]);
	const [eventsTypes, setEventsTypes] = useState([
		{ value: "", displayValue: "Selecione" },
	]);
	const [searchResults, setSearchResults] = useState([]);
	const [showFilters, setShowFilter] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	const [api, contextHolder] = notification.useNotification();
	const key = "updatable";

	useEffect(() => {
		getUser();
	}, []);

	useEffect(() => {
		getTypes();
	}, [company]);

	async function getUser() {
		try {
			const user = await Auth.currentAuthenticatedUser({
				bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
			});

			setUserName(user.username);
			setCompany(user.attributes["custom:company"]);
		} catch (err) {
			api.open({
				key,
				message: "Erro ao carregar usuário Logado",
				description: err.message,
				icon: (
					<FontAwesomeIcon icon="times-circle" style={{ color: "#ff0000" }} />
				),
			});
		}
	}

	async function getTypes() {
		try {
			const response = await axiosInstance.get("/api/enums/EEventTypes");
			const events = [{ value: "", displayValue: "Selecione" }];
			Object.values(response.data).forEach((event) => {
				events.push({
					value: event.code,
					displayValue: event.type,
				});
			});
			setEventsTypes(events);
			getEvents(events);
		} catch (err) {
			api.open({
				key,
				message: "Erro ao buscar tipos de Eventos",
				description: err.message,
				icon: (
					<FontAwesomeIcon icon="times-circle" style={{ color: "#ff0000" }} />
				),
			});
		}
	}

	async function getEvents(eventsTypes = null, cleaning = false) {
		setIsLoading(true);

		try {
			const body = {
				params: formatQuery(cleaning),
			};

			const response = await axiosInstance.get("/api/events/", body);
				const totalizers = {};
			eventsTypes.forEach((event, i) => {
				if (i > 0) {
					totalizers[event.displayValue] = {
						label: event.displayValue,
						quantity: 0,
					};
				}
			});

			totalizers["Total"] = {
				label: "Total",
				quantity: response.data.events.length,
			};

			response.data.events.forEach((event) => {
				totalizers[event.type_description].quantity += 1;
			});

			setTotalizers(Object.values(totalizers));
			setSearchResults(response.data.events);
		} catch (err) {
			setSearchResults([]);
			api.open({
				key,
				message: "Erro ao buscar os Eventos",
				description: err.message,
				icon: (
					<FontAwesomeIcon icon="times-circle" style={{ color: "#ff0000" }} />
				),
			});
		} finally {
			setIsLoading(false);
		}
	}

	function getInitialDates() {
		const today = new Date()
		const firstDay = formatDate(new Date(today.getFullYear(), today.getMonth(), 1))
		const lastDay = formatDate(new Date(today.getFullYear(), today.getMonth() + 1, 0))

		return [dayjs(firstDay, 'DD/MM/YYYY'), dayjs(lastDay, 'DD/MM/YYYY')]

	}

	function cleanFilters(e) {
		e.preventDefault();
		setUser("");
		setEvent("");
		setDate(getInitialDates());

		getEvents(eventsTypes, true);
	}

	function formatNumberTwoCaracters(number) {
		return number < 10 ? "0" + number : number

	}

	function formatDate(date = null, withHour = false) {

		date = date ? new Date(date) : new Date()

		const day = date.getDate();
		const month = date.getMonth() + 1;
		const hours = date.getHours()
		const minutes = date.getMinutes()

		const formatedDate = `${formatNumberTwoCaracters(day)}/${
			formatNumberTwoCaracters(month)
		}/${date.getFullYear()}`;

		if (withHour) return `${formatedDate} ${formatNumberTwoCaracters(hours)}:${formatNumberTwoCaracters(minutes)}`

		return formatedDate;
	}

	function formatQuery(justClient) {
		const params = {
			by_client: [company]
		};
		if (justClient) return params

		if (user !== "") params.by_person = user;
		if (event !== "") params.by_event_type = event;
		if (date !== null) {
			params.start_date = formatDate(date[0].$d).replaceAll("/", "-");
			params.end_date = formatDate(date[1].$d).replaceAll("/", "-");
		}

		return params;
	}

	const handleOnExportExcel = () => {
		const rows = searchResults.map((event) => {
			return {
				Código: event.type,
				"Tipo do Evento": event.type_description,
				"Usuário Vinculado": event.Person.registry_sub,
				"Nome do Usuário": event.Person.name,
				Data: formatDate(event.event_date, true),
			};
		});

		const eventsLabels = totalizers.map((totalizer) => totalizer.label);
		const eventsValues = totalizers.map((totalizer) => totalizer.quantity);

		exportToExcel(
			rows,
			eventsLabels,
			eventsValues,
			`eventList_${formatDate()}.xlsx`
		);
	};

	return (
		<>
			{contextHolder}
			<Toolbar userName={userName} />
			<Spinner isLoading={isLoading} />
			<div className="container">
				<div className="header">
					<h2>Lista de Eventos</h2>
					<div className="icons">
						<div
							className={searchResults.length > 0 ? "icon" : "disabled icon"}
							onClick={() => {
								if (searchResults.length > 0) {
									setShowFilter(!showFilters);
								}
							}}
						>
							{showFilters ? (
								<FontAwesomeIcon icon="times" />
							) : (
								<FontAwesomeIcon icon="filter" />
							)}
						</div>
						<div
							className={searchResults.length > 0 ? "icon" : "disabled icon"}
							onClick={() => {
								if (searchResults.length > 0) {
									handleOnExportExcel();
								}
							}}
						>
							<FontAwesomeIcon icon="file-excel" />
						</div>
					</div>
				</div>
				{showFilters && (
					<form>
						<Input
							id="user"
							label={"Usuário"}
							elementType={"input"}
							value={user}
							elementConfig={{ placeholder: "Digite o nome de usuário" }}
							changed={(event) => {
								setUser(event.target.value);
							}}
						/>
						<Input
							label={"Tipo do Evento"}
							elementType={"select"}
							id="events"
							elementConfig={{
								options: eventsTypes,
							}}
							value={event}
							changed={(event) => {
								setEvent(event.target.value);
							}}
						/>

						<Input
							label={"Data"}
							elementType={"dateRange"}
							id="date"
							value={date}
							elementConfig={{
								defaultValue: getInitialDates()
							}}
							classesToInput={"dateRange"}
							changed={(event) => setDate(event)}
						/>

						<Button
							className={"clean"}
							clicked={(e) => cleanFilters(e)}
							type="success"
							onClick
						>
							Limpar
						</Button>

						<Button
							className={"classic"}
							clicked={(e) => {
								e.preventDefault();
								getEvents(eventsTypes);
							}}
							onClick
						>
							Procurar
						</Button>
					</form>
				)}
				<table>
					<thead>
						<tr>
							<th>Código</th>
							<th>Tipo do Evento</th>
							<th>Nome do Usuário</th>
							<th>Data</th>
						</tr>
					</thead>

					<tbody>
						{searchResults &&
							searchResults.map((item) => {
								return (
									<tr key={item.id}>
										<td>{item.type}</td>
										<td>{item.type_description}</td>
										<td>{item.Person.name}</td>
										<td>{formatDate(item.event_date, true)}</td>
									</tr>
								);
							})}
					</tbody>
				</table>

				{searchResults.length === 0 && (
					<div className="no-data">
						<h2>Sem eventos para exibir</h2>
						<p>Verifique se os filtros correspondem à busca realizada</p>
					</div>
				)}

				<div className="totalizers">
					<h2>Totalizadores</h2>
					<div className="totalizers-box">
						{totalizers &&
							totalizers.map((item, i) => {
								return (
									<div key={i}>
										<p>{item.label}</p>
										<span>{item.quantity} </span>
									</div>
								);
							})}
					</div>
				</div>
			</div>
		</>
	);
}

export default Logs;
