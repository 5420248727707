import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faSignInAlt,
	faUserPlus,
	faHandPaper,
	faHome,
	faSpinner,
	faPollH,
	faFlask,
	faListAlt,
	faFilter,
	faFileExcel,
  faTimes,
	faTimesCircle
} from "@fortawesome/free-solid-svg-icons";

library.add(
	faSignInAlt,
	faUserPlus,
	faHandPaper,
	faHome,
	faSpinner,
	faPollH,
	faFlask,
	faListAlt,
	faFilter,
	faFileExcel,
  faTimes,
	faTimesCircle
);

function IconLib() {}

export default IconLib;
