import React, {useEffect, useState} from 'react';
import Toolbar from '../../components/Navigarion/Toolbar/Toolbar';
import queryString from 'query-string';
import { useHistory } from "react-router-dom";

import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import Button from '../../components/UI/Button/Button';
//import { Hidden } from 'aws-amplify-react/lib-esm/AmplifyTheme';
import SpinnerLittle from '../../components/UI/Spinner/SpinnerLittle'
import Input from '../../components/UI/Imput/Imput';

const Report = props => {

    const history = useHistory()

    const [acquisition, setAcquisition] = useState();
    const [waitMens, setWaitMens] = useState((<div className="loading-results"><SpinnerLittle/>Aguarde estamos buscando os resultado.</div>));

    const [showForm, setShowForm] = useState(true)
    const [id, setId] = useState({
        value: '',
        valid: false
    })
    const [cpf, setCpf] = useState({
        value: '',
        valid: false
    })

    const [showFilter, setShowFilter] = useState(false);

    const [filterArray, setFilterArray] = useState(null);
    const [filteredResult, setFilteredResult] = useState(null);

    useEffect(() => {        
        const filterStorage = localStorage.getItem('progenos-filter');
        if (filterStorage) {            
            setFilterArray(JSON.parse(filterStorage));
        } 
    }, [])

    useEffect(()=>{
        if (filterArray != null) {
            localStorage.setItem('progenos-filter', JSON.stringify(filterArray))
        }
    },[filterArray])

    

    const [personData, setPersonData] = useState(
        {
            id: 0,
            idade: 0,
            sexo:"--",
            etnia:"--",
            data:"--",
            nome:""
        }
    );

    const [resultados, setResultados] = useState(null);

    useEffect(()=>{
        if (resultados != null ) {
            
            let filteredResult = setFilterObj({...resultados}, filterArray);                    
            setFilteredResult(filteredResult);
        }
        
    }, [resultados])

    const downloadSamples = ()=> {        
        
        if (typeof acquisition !== "undefined") {

            let csvContent = "data:text/csv;charset=utf-8,";
            let csvContentHomo = "data:text/csv;charset=utf-8,";
            //let selectedFilter = this.state.prevs.map(el=>el.sel && el);
    
            const opts = acquisition.searchResult.opts;
            const ntcs = acquisition.searchResult.tpr;
            const black = acquisition.searchResult.black;
            const id = acquisition.searchResult.personData.id;
            
            const acquisitionHomo = acquisition.searchResult.acquisitionHomo;

            const seq   = opts.map((v,i)=>i+1);
            const seqTp = ntcs.map((v,i)=>i+1);
            const seqBlack = black.map((v,i)=>i+1);
            
            const seqHomo = acquisitionHomo.map((v,i)=>i+1);
    
            csvContent += "id," + seqBlack.toString() +  "," + seq.toString() + "," + seqTp  + "\n";         
            csvContent += id + "," + black.toString() +  "," + opts.toString() + "," +  ntcs.toString() + "\n";  
            
            csvContentHomo += "id," + seqHomo + "\n";
            csvContentHomo += id + "," + acquisitionHomo + "\n";
    
            let encodedUri = encodeURI(csvContent);
            let encodedUriHomo = encodeURI(csvContentHomo);
            
            let link = document.createElement("a");
            let link2 = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link2.setAttribute("href", encodedUriHomo);
            link.setAttribute("download", "data.csv");
            link2.setAttribute("download", "dataHomo.csv");
            document.body.appendChild(link);
            document.body.appendChild(link2);
            link.click();  
            link2.click();  
        }


    }

    const diaDHandler = ()=>{

        try {

            
            console.log("Ativando o Dia D...");
            const resultD = {bios: [
                {
                    subGroup : [{
                        name: "Outros",
                        markers: [
                            resultados.bios[1].subGroup[1].markers[4]
                        ]
                    }],
                    title : "Análise Bioquímico"
                }
                
            ]}

            setResultados(resultD)
            console.log("Dia D ativado!!");
            
        } catch (error) {
            console.log(error);
        }
        

        //resultados.bios[1].subGroup[1].markers[4]

    }

    const searchRegestry = async (_id, prontuario, _company) => {    
                   
        let query = {
            queryStringParameters: {  
                cpf: prontuario,
                id: _id,
                company: _company
            }
        }

        //personalID=77&acquisitionID=77
        API.get('aquisitor-api', '/acquisition', query ).then(response => {            
           
            if(response.statusCode ===  200) {
                setAcquisition({
                    searchResult:response.body
                });                      
                setPersonData(response.body.personData);
            }

        }).catch(error => {
            console.log("Erro")   
        });


        getResult(prontuario, _id)

        
    }

    const getResult = (cpf,id, times=0)=>{
        
        let query = {
            queryStringParameters: {  
                personalID: cpf,
                acquisitionID: id                
            }
        }

        API.get('result-api', '/', query ).then(response => {                       
            if(response.statusCode ===  200) {                                               
                
                if(!response.body.bios || response.body.bios === null) {
                    setWaitMens((<div className="loading-results"><SpinnerLittle/>Ainda estamos processando seus dados, aguarde mais alguns minutos ou volte mais tarde.</div>))
                    if(times < 6) {
                        const time = 15000 + times*1000
                        console.log("aguardando: " + time); 
                        setTimeout(() => { 
                            getResult(cpf,id, times+1)                            
                          }, time);
                    } else {
                        setWaitMens((<div className="loading-results">Estamos com dificuldade para calcular o resultado, volte dentro de alguns minutos.</div>))
                        console.log("time out!")
                    }
                    
                } else {
                    let results = response.body;
                    // let filteredResult = setFilterObj(results, filterArray);
                    
                    setResultados(results);
                    
                }
            }

        }).catch(error => {
            console.log("Erro: " + error)           
        });
    }

    const setFilterObj = (results, filterArray)=> {
        
        const filterObj = results;            
        if (filterArray == null) {
            for (let i = 0; i < results.bios.length; i++) {
                const group = results.bios[i];
                results.bios[i].show = true;                                        
                for (let y = 0; y < group.subGroup.length; y++) {
                    const subGroup = group.subGroup[y];   
                    filterObj.bios[i].subGroup[y].show = true;
                    for (let x = 0; x < subGroup.markers.length; x++) {                            
                        filterObj.bios[i].subGroup[y].markers[x][7] = true
                    }
                }                
            }
           setFilterArray(filterObj);
        } else {                    
            for (let i = 0; i < results.bios.length; i++) {
                const group = results.bios[i];     
                results.bios[i].show = true;                                         
                for (let y = 0; y < group.subGroup.length; y++) {
                    const subGroup = group.subGroup[y];   
                    filterObj.bios[i].subGroup[y].show = filterArray.bios[i].subGroup[y].show;
                    for (let x = 0; x < subGroup.markers.length; x++) {                        
                        filterObj.bios[i].subGroup[y].markers[x][7] = filterArray.bios[i].subGroup[y].markers[x][7];
                    }
                }                
            }
        }

        return filterObj;
    }

    const updateCpf = (e) => {
        let newValue = {
            value: e.target.value,
            valid: false
        }
        if (cpf.value.length > 0 && e.target.value.length > 0) {
            newValue.valid = true           
        }
        
        setCpf(newValue) 
    }

    const updateId = (e) => {
        let newValue = {
            value: e.target.value,
            valid: false
        }
        if (cpf.value.length > 0 && e.target.value.length > 0) {
            newValue.valid = true
        }
        setId(newValue) 
    }

    const buscaResultado = ()=>{        
        setShowForm(false)
        if (id.valid) {                
            history.push(`/report?prontuario=${cpf.value}&id=${id.value}`)
        } else {
            history.push("/acquisition?prontuario="+cpf.value)
        }
        
    }

    const printRedferenceValues = (bioA, bioB)=>{

        const bioAMultLine = Array.isArray(bioA) ?  bioA.map(line=><>{line}<br/></>) : bioA
        const bioBMultLine = Array.isArray(bioB) ?  bioB.map(line=><>{line}<br/></>) : bioB
        

        if (bioA === bioB) {
            return ( <td colSpan="2">{bioAMultLine}</td> )

        } else {
            return (
                <>
                <td>{bioAMultLine}</td> 
                <td>{bioBMultLine}</td>
                </>
            )
        }
    }


    // const checkFilterValue = (name) => {
    //     const item = filterArray.filter(item=>item===name)
    //     
    //     console.log(item)
    //     return item.length ? item[0].show : false
    // }

    const changeFilterArray = (e)=>{
        
        let newArrayFilter = {...filteredResult};        
        for (let i = 0; i < filterArray.bios.length; i++) {            
                      
            for (let y = 0; y < filterArray.bios[i].subGroup.length; y++) {

                if (filterArray.bios[i].subGroup[y].name === e.target.id) {
                    newArrayFilter.bios[i].subGroup[y].show = !newArrayFilter.bios[i].subGroup[y].show;
                    
                    for (let x = 0; x < newArrayFilter.bios[i].subGroup[y].markers.length; x++) {
                        newArrayFilter.bios[i].subGroup[y].markers[x][7] = newArrayFilter.bios[i].subGroup[y].show;
                    }
                    break
                }
                for (let x = 0; x < newArrayFilter.bios[i].subGroup[y].markers.length; x++) {
                    if (newArrayFilter.bios[i].subGroup[y].markers[x][0] === e.target.id) {
                        newArrayFilter.bios[i].subGroup[y].markers[x][7] = !newArrayFilter.bios[i].subGroup[y].markers[x][7];
                        if(!newArrayFilter.bios[i].subGroup[y].show) {
                            newArrayFilter.bios[i].subGroup[y].show = true;
                        }
                        break
                    }
                }
            }
            
            filterArray.bios[i].show = filterArray.bios[i].subGroup.filter(group=>group.show).length > 0 ? true : false;

        }
        setFilterArray(newArrayFilter);        
    }

    const printZeroObs = (bios)=> {        
        const biosWithZero = bios.every((bio=>bio[6] !== "*" ))
        if (biosWithZero){
            return <></>
        } else {
            return <p>* Os resultados obtidos para os seus exames estão abaixo dos limites de detecção determinada para esse método.</p>
        }
    }

    const printTable = (resultados) =>{ 
        if (resultados == null) {
            return <></>
        }
        return resultados.bios.map((group) => {
            
            if (group.show || showFilter) {
                return (
                    <div key={group.title} className="grupo-exames">
                        <h4>{group.title}</h4>
                            { 
                                group.subGroup.map((subBio)=>{ 
                                                                  
                                    if (!subBio.markers.length ) {
                                         return(<></>);
                                    } else if (subBio.show || showFilter) {
                                        return(
                                            <div key={subBio.name} className="sub-grupo">   
                                                {subBio.name ? <h5>{showFilter ? <><input checked={subBio.show} id={subBio.name} onChange={changeFilterArray} type="checkbox" value={subBio.name} /> -</> : <></>} {subBio.name}</h5> : <></>}
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th colSpan="2">Valores referência</th>                                               
                                                        </tr>
                                                        <tr>                                                
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th>Homem</th> 
                                                            <th>Mulher</th>                                               
                                                        </tr>        
                                                    </thead>
        
                                                    <tbody>
                                                        {
                                                            subBio.markers.map((bio)=>{
        
                                                                if (bio[7] || showFilter) {
                                                                    return(
                                                                        <tr key={bio[0]} >
                                                                        
                                                                            
                                                                            <td>{showFilter ? <><input checked={bio[7]} id={bio[0]} onChange={changeFilterArray} type="checkbox" value={bio[0]} /> -</>: ''} {bio[0]}</td>
                                                                            {/* <td>{(Math.floor(Math.random() * (bio[4] - bio[3])) + bio[3]).toLocaleString("br-PT")} </td>    */}
                                                                            <td>{bio[6]} </td>   
                                                                            <td>{bio[1]}</td>
                                                                            { printRedferenceValues(bio[2], bio[3])} 
                                                                            
                                                                            
                                                                        </tr>
                                                                    )  
                                                                }
                                                                return <></>
                                                                                                                      
                                                            })
                                                        }
                                                        
                                                    </tbody>
        
                                                </table>
                                                {printZeroObs(subBio.markers)}
                                            </div>
                                            )
                                    }
    
                                    
    
    
                                })
                             }
                             
                    </div>
                )
            } else {
                <></>
            }
        })
    }

    useEffect(() => {
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {            

            const parsedQuery = queryString.parse(props.location.search);
            const { id, prontuario, cpf } = parsedQuery;            

            if ((id && cpf) || (id && prontuario)) {
                setShowForm(false)                
                searchRegestry(id, (cpf ? cpf: prontuario), user.attributes["custom:company"]);
            } 
            
        }).catch(err => {
            return err;
        });


        
    }, [props.location.search])

    const filterHandler = () => {        
        setShowFilter(showFilter=>!showFilter)
    }

    if(showForm) {

        return(
            <div className="report-main">
            <Toolbar userName={"usuario"}/>
    
    
               <div className={"busca-registro"}>
                <h4>Insira os dados para buscas.</h4>           
                <form>                    
                    <Input                         
                        label={"Nº prontuário"}
                        elementType={"input"}                        
                        value={cpf.value}
                        invalid={cpf.valid}
                        shouldValidate={false}                        
                        changed={(e)=>updateCpf(e)} />

                    <Input                         
                        label={"ID"}
                        elementType={"input"}                        
                        value={id.value}
                        invalid={id.valid}
                        shouldValidate={false}                        
                        changed={(e)=>updateId(e)} />

                    <Button className={"classic"} disabled={!(cpf.valid)} clicked={buscaResultado} typeype="success" onClick >Buscar</Button>
                </form>
            
            </div>
            </div>
        )

    } else {

        return(
            <div className="report-main">
                <Button clicked={diaDHandler} className="dia-d">D</Button>
            <Toolbar userName={"usuario"}/>
    
                <div className="subHeader">
                <table className="report-info">
                    <thead>
                        <tr>            
                            <th>ID</th>
                            <th>Data de nascimento</th>
                            <th>Etnia</th>
                            <th>Sexo</th>
                            <th>Data Aquisição</th> 
                            <th>Nome</th>            
                        </tr>        
                    </thead>
                    <tbody>
                        <tr>
                            <td>{personData.id}</td>
                            <td>{personData.idade}</td>
                            <td>{personData.etnia}</td>
                            <td>{personData.sexo}</td>
                            <td>{personData.data}</td>
                            <td>{personData.nome}</td>
                        </tr>
                    </tbody>
                </table>

                <div className="filtro"><Button clicked={filterHandler}>{showFilter ? 'Aplicar Filtro' : 'Filtrar'}</Button></div>
                </div>
                
                
                {showFilter && <p>Selecione os exames para fazer o filtro.</p>}
                {filteredResult ? printTable(filteredResult) : waitMens}
          
            <p><Button className="hideTrick" clicked={downloadSamples}>.</Button></p>

            <p>A tecnologia Progenos está em constante desenvolvimento e os resultados dos exames são obtidos por método de inteligência artificial associado a espectroscopia de infravermelho próximo, pelo mapeamento do centro da palma da mão. Os resultados têm por finalidade a triagem, não se recomenda utilizar os resultados das análises como diagnostico na decisão soberana do profissional médico.</p>
            
            </div>
        )

    }
    

}

export default Report;